import { Pretend, IPretendRequest, Get } from 'pretend';

import * as dtos from './dtos';
import {
    LocalDate,
    ZonedDateTime,
    parseApiDates
} from '@spa-frontend/date-lib';

const parseResult = (props: string[]) => {
    return (
        _target: Object,
        _key: string | symbol,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        descriptor: TypedPropertyDescriptor<any>
    ) => {
        const fn = descriptor.value;

        descriptor.value = async function(...args: unknown[]) {
            const result = await fn.apply(this, args);

            const tupleProps = props.map(prop => prop.split('.'));

            return parseApiDates(result, tupleProps);
        };

        return descriptor;
    };
};

export interface NoxBookableItemController {
    getBookableCourseItem(itemId: number): Promise<dtos.NoxBookableItemDto>;

    getBookableCourseItemPublic(
        itemId: number
    ): Promise<dtos.NoxBookableItemDto>;

    getBookableSingleItem(
        benefitId: number,
        query: {
            employeeIds: number[];
            organizationUnitId: number;
            startDateTime: ZonedDateTime;
        }
    ): Promise<dtos.NoxBookableItemDto>;

    getBookableSingleItemPublic(
        benefitId: number,
        query: {
            employeeIds: number[];
            organizationUnitId: number;
            startDateTime: ZonedDateTime;
        }
    ): Promise<dtos.NoxBookableItemDto>;

    getCoursePlan(query: {
        benefitCategoryIds?: number[];
        benefitIds?: number[];
        courseAvailability?:
            | 'ALL'
            | 'BOOKABLE'
            | 'BOOKABLE_OR_WITH_WAITING_LIST';
        employeeIds?: number[];
        endDate?: LocalDate;
        isStudioEvent?: boolean;
        maxResults?: number;
        organizationUnitIds?: number[];
        search?: string;
        startDate: LocalDate;
    }): Promise<dtos.NoxBookableItemDto[]>;

    getCoursePlanPublic(query: {
        benefitCategoryIds?: number[];
        benefitIds?: number[];
        courseAvailability?:
            | 'ALL'
            | 'BOOKABLE'
            | 'BOOKABLE_OR_WITH_WAITING_LIST';
        employeeIds?: number[];
        endDate?: LocalDate;
        isStudioEvent?: boolean;
        maxResults?: number;
        organizationUnitIds?: number[];
        search?: string;
        startDate: LocalDate;
    }): Promise<dtos.NoxBookableItemDto[]>;

    getCoursePlanPublicWithDefaultHomeStudio(query: {
        benefitCategoryIds?: number[];
        benefitIds?: number[];
        courseAvailability?:
            | 'ALL'
            | 'BOOKABLE'
            | 'BOOKABLE_OR_WITH_WAITING_LIST';
        employeeIds?: number[];
        endDate?: LocalDate;
        isStudioEvent?: boolean;
        maxResults?: number;
        organizationUnitIds?: number[];
        search?: string;
        startDate: LocalDate;
    }): Promise<dtos.NoxBookableItemDto[]>;

    getForTrialSessionPublic(
        benefitId: number,
        query: {
            endDate: LocalDate;
            slotLimit?: string;
            startDate: LocalDate;
            timePeriodIds?: number[];
            timePeriods?: string[];
        }
    ): Promise<dtos.NoxBookableBenefitItemDto>;

    getNextAvailableCoursePlanPublic(query: {
        benefitCategoryIds?: number[];
        benefitIds?: number[];
        courseAvailability?:
            | 'ALL'
            | 'BOOKABLE'
            | 'BOOKABLE_OR_WITH_WAITING_LIST';
        employeeIds?: number[];
        isStudioEvent?: boolean;
        maxResults?: number;
        organizationUnitIds?: number[];
        search?: string;
        startDate: LocalDate;
    }): Promise<dtos.NoxNextAvailableBookableItemsDto>;

    /**
     * @deprecated
     */
    getNextAvailableSingleBenefitPublic(
        benefitId: number,
        query: {
            employeeIds?: number[];
            maxDaysLookup?: number;
            organizationUnitIds?: number[];
            slotLimit?: string;
            startDate: LocalDate;
            timePeriodIds?: number[];
            timePeriods?: string[];
        }
    ): Promise<dtos.NoxNextAvailableBookableBenefitItemDto>;

    getSingleBenefit(
        benefitId: number,
        query: {
            employeeIds?: number[];
            endDate: LocalDate;
            organizationUnitIds?: number[];
            slotLimit?: string;
            startDate: LocalDate;
            timePeriodIds?: number[];
            timePeriods?: string[];
        }
    ): Promise<dtos.NoxBookableBenefitItemDto>;

    getSingleBenefitPublic(
        benefitId: number,
        query: {
            employeeIds?: number[];
            endDate: LocalDate;
            organizationUnitIds?: number[];
            slotLimit?: string;
            startDate: LocalDate;
            timePeriodIds?: number[];
            timePeriods?: string[];
        }
    ): Promise<dtos.NoxBookableBenefitItemDto>;

    getSingleBenefits(query: {
        benefitCategoryIds?: number[];
        employeeIds?: number[];
        organizationUnitIds?: number[];
        search?: string;
        timePeriodIds?: number[];
        timePeriods?: string[];
    }): Promise<dtos.NoxBookableBenefitItemDto[]>;

    getSingleBenefitsPublic(query: {
        benefitCategoryIds?: number[];
        employeeIds?: number[];
        organizationUnitIds?: number[];
        search?: string;
        timePeriodIds?: number[];
        timePeriods?: string[];
    }): Promise<dtos.NoxBookableBenefitItemDto[]>;

    getUpcomingCoursesPublic(query: {
        organizationUnitId: number;
    }): Promise<dtos.NoxBookableItemDto[]>;
}

export interface NoxStudioController {
    // tslint:disable:no-any
    getAnyStudioLogo(): Promise<any>;
    // tslint:enable:no-any

    getHomeStudio(): Promise<dtos.NoxStudioExtendedDto>;

    getRevealUtilization(
        studioId: number
    ): Promise<dtos.WrappedResultOfboolean>;

    getRevealUtilizationPublic(
        studioId: number
    ): Promise<dtos.WrappedResultOfboolean>;

    getSepaAgreementText(studioId: number): Promise<string>;

    // tslint:disable:no-any
    getStudioLogo(studioId: number): Promise<any>;
    // tslint:enable:no-any

    getStudioPublic(studioId: number): Promise<dtos.NoxStudioExtendedDto>;

    getStudiosPublic(): Promise<dtos.NoxStudioExtendedDto[]>;

    getUtilization(studioId: number): Promise<dtos.UtilizationDto>;

    getUtilizationPublic(studioId: number): Promise<dtos.UtilizationDto>;
}

export interface NoExcuse {
    NoxBookableItemController: NoxBookableItemController;
    NoxStudioController: NoxStudioController;
}

// tslint:disable:no-any

class NoxBookableItemControllerBlueprint implements NoxBookableItemController {
    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/v1/bookableitems/course/{itemId}')
    public getBookableCourseItem(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/course/{itemId}')
    public getBookableCourseItemPublic(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/v1/bookableitems/single/{benefitId}', true)
    public getBookableSingleItem(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/single/{benefitId}', true)
    public getBookableSingleItemPublic(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/v1/bookableitems/course', true)
    public getCoursePlan(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/course', true)
    public getCoursePlanPublic(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/course/withdefaulthomestudio', true)
    public getCoursePlanPublicWithDefaultHomeStudio(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/benefit/{benefitId}/trialsession', true)
    public getForTrialSessionPublic(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'bookableItems.slots.earliestBookingDateTime',
        'bookableItems.slots.endDateTime',
        'bookableItems.slots.latestBookingDateTime',
        'bookableItems.slots.startDateTime',
        'date'
    ])
    @Get('/nox/public/v1/bookableitems/course/nextavailable', true)
    public getNextAvailableCoursePlanPublic(_p0: any): any {
        /**/
    }

    @parseResult([
        'bookableItem.slots.earliestBookingDateTime',
        'bookableItem.slots.endDateTime',
        'bookableItem.slots.latestBookingDateTime',
        'bookableItem.slots.startDateTime',
        'bookableItem.upcomingAppointmentDateTime',
        'date'
    ])
    @Get('/nox/public/v1/bookableitems/benefit/{benefitId}/nextavailable', true)
    public getNextAvailableSingleBenefitPublic(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/v1/bookableitems/benefit/{benefitId}', true)
    public getSingleBenefit(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/benefit/{benefitId}', true)
    public getSingleBenefitPublic(_p0: any, _p1: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/v1/bookableitems/benefits', true)
    public getSingleBenefits(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime',
        'upcomingAppointmentDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/benefits', true)
    public getSingleBenefitsPublic(_p0: any): any {
        /**/
    }

    @parseResult([
        'slots.earliestBookingDateTime',
        'slots.endDateTime',
        'slots.latestBookingDateTime',
        'slots.startDateTime'
    ])
    @Get('/nox/public/v1/bookableitems/course/upcoming', true)
    public getUpcomingCoursesPublic(_p0: any): any {
        /**/
    }
}

class NoxStudioControllerBlueprint implements NoxStudioController {
    @Get('/nox/public/v1/studios/any/logo')
    public getAnyStudioLogo(): any {
        /**/
    }

    @Get('/nox/v1/studios/home')
    public getHomeStudio(): any {
        /**/
    }

    @Get('/nox/v1/studios/{studioId}/utilization/reveal')
    public getRevealUtilization(_p0: any): any {
        /**/
    }

    @Get('/nox/public/v1/studios/{studioId}/utilization/reveal')
    public getRevealUtilizationPublic(_p0: any): any {
        /**/
    }

    @Get('/nox/public/v1/studios/{studioId}/sepa/agreement')
    public getSepaAgreementText(_p0: any): any {
        /**/
    }

    @Get('/nox/public/v1/studios/{studioId}/logo')
    public getStudioLogo(_p0: any): any {
        /**/
    }

    @Get('/nox/public/v1/studios/{studioId}')
    public getStudioPublic(_p0: any): any {
        /**/
    }

    @Get('/nox/public/v1/studios')
    public getStudiosPublic(): any {
        /**/
    }

    @Get('/nox/v1/studios/{studioId}/utilization')
    public getUtilization(_p0: any): any {
        /**/
    }

    @Get('/nox/public/v1/studios/{studioId}/utilization')
    public getUtilizationPublic(_p0: any): any {
        /**/
    }
}

// tslint:enable:no-any

export function getClient(
    endpoint: string,
    token: string | undefined,
    configure: (client: Pretend) => Pretend = client => client
): NoExcuse {
    const bearerToken = (request: IPretendRequest) => {
        if (token) {
            request.options.headers = new Headers(request.options.headers);
            request.options.headers.set('Authorization', `bearer ${token}`);
        }
        return request;
    };

    return {
        NoxBookableItemController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(NoxBookableItemControllerBlueprint, endpoint),
        NoxStudioController: configure(Pretend.builder())
            .requestInterceptor(bearerToken)
            .target(NoxStudioControllerBlueprint, endpoint)
    };
}
