import { i18n } from './i18n';
import { getClient } from './studio-api';

interface UrlParams {
    id: string;
    tenant: string;
    organizationUnitId?: number;
    origin: string;
    height?: 'auto';
    baseHost?: string;
    disableUtilization?: boolean;
    disableEmployeeExpertises?: boolean;
}

interface Id {
    tenant: string;
    organizationUnitId?: number;
}

export type ViewState = 'mobile' | 'tablet' | 'desktop';
export type Env = 'dev' | 'stage' | 'prod' | 'fibo';

const mediaQueryMobile = window.matchMedia('(max-width: 767px)');
const mediaQueryTablet = window.matchMedia(
    '(min-width: 768px) and (max-width: 1279px)'
);
const mediaQueryDesktop = window.matchMedia('(min-width: 1280px)');

function matchLegacyQueryParams(encodedId: string): Id | undefined {
    try {
        const id = atob(encodedId);
        const tenantMatcher = id.match(/(?:t|tenant)=([\w-]+)/);
        const orgUnitMatcher = id.match(/(?:o|organizationUnitId)=(\d+)/);
        const tenant = tenantMatcher ? tenantMatcher[1] : undefined;
        const organizationUnitId = orgUnitMatcher
            ? parseInt(orgUnitMatcher[1], 10)
            : undefined;

        if (tenant) {
            return {
                tenant,
                organizationUnitId
            };
        }
    } catch (e) {}

    return undefined;
}

function getUrlParams() {
    const search = location.search.replace(/^\?/, '');

    return search
        .split('&')
        .map(str => str.split('='))
        .reduce((memo, val) => {
            const key = val[0] as keyof UrlParams;
            const value = isFinite(val[1] as any)
                ? parseInt(val[1], 10)
                : decodeURIComponent(val[1]);

            (memo as any)[key] = (() => {
                if (value === 'true') {
                    return true;
                }

                if (value === 'false') {
                    return false;
                }

                return value;
            })();

            if (key === 'id') {
                memo = {
                    ...memo,
                    ...matchLegacyQueryParams(value as string)
                };
            }

            return memo;
        }, {} as UrlParams);
}

export function onResize(callback: () => void) {
    window.addEventListener('resize', callback);

    return () => window.removeEventListener('resize', callback);
}

export function onViewState(callback: (viewState: ViewState) => void) {
    const mobileHandler = (event: MediaQueryListEvent) => {
        if (event.matches) {
            callback('mobile');
        }
    };

    const tabletHandler = (event: MediaQueryListEvent) => {
        if (event.matches) {
            callback('tablet');
        }
    };

    const desktopHandler = (event: MediaQueryListEvent) => {
        if (event.matches) {
            callback('desktop');
        }
    };

    mediaQueryMobile.addListener(mobileHandler);
    mediaQueryTablet.addListener(tabletHandler);
    mediaQueryDesktop.addListener(desktopHandler);

    if (mediaQueryMobile.matches) {
        callback('mobile');
    }

    if (mediaQueryTablet.matches) {
        callback('tablet');
    }

    if (mediaQueryDesktop.matches) {
        callback('desktop');
    }

    return () => {
        mediaQueryMobile.removeListener(mobileHandler as any);
        mediaQueryTablet.removeListener(tabletHandler as any);
        mediaQueryDesktop.removeListener(desktopHandler as any);
    };
}

export function postMessage(message: any, type: string) {
    parent.postMessage({ type, data: message }, config.origin);
}

export function getMessage<T = any>(type: string, callback: (data: T) => void) {
    const outerCallback = (e: MessageEvent) => {
        if (e.data.type === type) {
            callback(e.data.data);

            window.removeEventListener('message', outerCallback);
        }
    };

    window.addEventListener('message', outerCallback);

    return () => window.removeEventListener('message', outerCallback);
}

class Config implements UrlParams {
    public urlParams!: UrlParams;

    public ready = false;

    public zoneId: string = 'Europe/Berlin';

    public getBackendUrl() {
        return `${location.origin}/`;
    }

    public getSponsorshipBackendUrl() {
        return `${location.origin}/sponsorship/`;
    }

    public get id() {
        return this.urlParams.id;
    }

    public get tenant() {
        return this.urlParams.tenant;
    }

    public get organizationUnitId() {
        return this.urlParams.organizationUnitId;
    }

    public get origin() {
        return this.urlParams.origin;
    }

    public get height() {
        return this.urlParams.height;
    }

    public get baseHost() {
        return this.urlParams.baseHost;
    }

    constructor() {
        this.urlParams = getUrlParams();

        (async () => {
            if (!this.urlParams.tenant && !this.urlParams.organizationUnitId) {
                const studioApi = getClient(this.getSponsorshipBackendUrl());

                const studio = await studioApi.StudioController.getSingleStudio(
                    this.urlParams.id,
                    { bookingPortal: false }
                );

                this.urlParams.tenant = studio.magiclineTenant;
                this.urlParams.organizationUnitId = studio.magiclineId;
                this.zoneId = studio.zoneId;
            }

            await i18n.waitFor();

            this.ready = true;
        })();
    }
}

export const config = new Config();
