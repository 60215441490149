import { LocalDate, ZonedDateTime } from '@spa-frontend/date-lib';
import { config } from '../config';
import { NoxLocationDto, NoxEmployeeDto, NoxStudioDto } from './dtos';
import { getClient } from './get-client';

export type Daytime = 'morning' | 'noon' | 'afternoon' | 'evening';

const parseNull = (item: any): any => {
    if (item === null) {
        return undefined;
    }

    if (Array.isArray(item)) {
        return item.map(parseNull);
    }

    if (typeof item === 'object') {
        return Object.keys(item).reduce(
            (prev: {}, key: string) => ({
                ...prev,
                [key]: parseNull(item[key])
            }),
            {}
        );
    }

    return item;
};

const parseError = (error: any) => {
    if (Array.isArray(error)) {
        return error[0].errorCode;
    }

    return error.errorCode;
};

export const getApi = () => {
    return getClient(
        config.getBackendUrl(),
        {
            'X-Tenant': config.tenant,
            'X-Nox-Client-Type': 'WEB'
        },
        pretend =>
            pretend.decode(async response => {
                const json = await response.json();

                if (response.status === 200) {
                    return parseNull(json);
                }

                throw new Error(parseError(json));
            })
    );
};

export interface Course {
    id: number;
    name: string;
    description?: string;
    bookedParticipants?: number;
    maxParticipants?: number;
    startDate: ZonedDateTime;
    endDate: ZonedDateTime;
    locations: NoxLocationDto[];
    employees: NoxEmployeeDto[];
    colorHex: string;
    imageUrl?: string;
    studio: NoxStudioDto;
    bookable: boolean;
}

export interface CourseGroups {
    // prettier-ignore
    [key: string]: Course[];
}

export async function getCoursePlanPublic(
    startDate: LocalDate,
    endDate: LocalDate,
    organizationUnitIds: number[] = []
): Promise<Course[]> {
    const courses = await getApi().NoxBookableItemController.getCoursePlanPublic(
        {
            startDate,
            endDate,
            organizationUnitIds
        }
    );

    return courses
        .map<Course>(course => ({
            id: course.id,
            name: course.name,
            description: course.description,
            colorHex: course.colorHex as any,
            bookedParticipants: course.bookedParticipants,
            maxParticipants: course.maxParticipants,
            startDate: course.slots[0].startDateTime,
            endDate: course.slots[0].endDateTime,
            locations: course.slots[0].locations,
            employees: course.slots[0].employees,
            imageUrl: course.imageUrl,
            studio: course.slots[0].studio,
            bookable: course.slots[0].bookable
        }))
        .sort((a, b) => {
            if (a.startDate < b.startDate) {
                return -1;
            }

            if (a.startDate > b.startDate) {
                return 1;
            }

            return 0;
        });
}

export async function getStudios() {
    return getApi().NoxStudioController.getStudiosPublic();
}
