import { ZonedDateTime } from '@spa-frontend/date-lib';
import { Component, h } from 'preact';
import { Course } from '../../api';
import { config } from '../../config';
import { i18n } from '../../i18n';
import { classnames } from '../../utils/classnames';
import { compatibility } from '../compatibility';
import { Layout } from '../layout';
import { Progress } from '../progress';
import { Text } from '../text';
import styles from './index.css';
import { getEmployeeFullName } from "../../utils/employee";

const Container = compatibility('div');

interface CourseBoxProps<T = Course> {
    course: T;
    style?: any;
    onClick(course: T, ref?: HTMLDivElement): void;
}

const Color = (props: { color: string }) => (
    <div className={styles.circle} style={{ backgroundColor: props.color }} />
);

export class CourseBox extends Component<CourseBoxProps> {
    private ref?: HTMLDivElement;

    constructor(props: CourseBoxProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public render() {
        const className = classnames(
            styles.container,
            this.props.course.startDate.isBefore(
                ZonedDateTime.now(this.props.course.studio.zoneId)
            ) && styles.isPast
        );

        const { disableUtilization } = config.urlParams;

        return (
            <Container
                className={className}
                style={this.props.style}
                onClick={this.onClick}
            >
                <Layout between="S" stack>
                    {this.renderTitle()}
                    {this.renderDate()}
                    {disableUtilization ||
                    !this.props.course.bookable ||
                    this.props.course.bookedParticipants === 0
                        ? null
                        : this.renderProgress()}
                    <Layout stack between="XS">
                        {this.renderTrainer()}
                        {this.renderLocation()}
                    </Layout>
                </Layout>
            </Container>
        );
    }

    private renderTitle() {
        const { course } = this.props;

        return (
            <Layout padding={[0, 0, 'M']} hAlign="space-between">
                <h3 className={styles.title}>{course.name}</h3>
                <Color color={course.colorHex} />
            </Layout>
        );
    }

    private renderDate() {
        const { course } = this.props;

        return (
            <div>
                <Text color="coolgrey">
                    {course.startDate.toTimeString()} -{' '}
                    {course.endDate.toTimeString()}
                </Text>
            </div>
        );
    }

    private renderProgress() {
        const { course } = this.props;

        return (
            <Progress
                current={course.bookedParticipants || 0}
                max={course.maxParticipants || 0}
            />
        );
    }

    private renderLocation() {
        const location = this.props.course.locations
            .map(location => location.name)
            .join(', ');

        if (location) {
            return <Text ellipsis>{location}</Text> || null;
        }

        return null;
    }

    private renderTrainer() {
        const trainer = this.props.course.employees
            .map(employee => getEmployeeFullName(employee))
            .join(', ');

        if (trainer) {
            return (
                <Text>{i18n.__('ms.widget.courseplan.with', trainer)}</Text> ||
                null
            );
        }

        return null;
    }

    private onClick() {
        this.props.onClick(this.props.course, this.ref);
    }
}
